/* You can add global styles to this file, and also import other style files */
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
// typography
.container{
    padding-left: 100px;
    padding-right: 100px;
}
.text-center{
    text-align: center;
}
.pr-10{
    padding-right: 10px;
}
.pr-15{
    padding-right: 15px;
}
.pt-15{
    padding-top: 15px;
}
.pr-25{
    padding-right: 25px;
}
.pr-45{
    padding-right: 45px;
}
.pl-45{
    padding-left: 45px;
}
.pr-70{
    padding-right: 70px;
}
.pl-90 {
    padding-left: 90px;
}
.px-15{
    padding-right:15px;
    padding-left:15px;
}
.px-50{
    padding-left: 50px;
    padding-right: 50px;
}
.pt-60{
    padding-top:60px;
}
.pt-15{
    padding-top:15px;
}
.d-flex-acenter{
    display: flex;
    align-items: center;
}
.d-flex-center-end{
    display: flex;
    align-items: center;
    justify-content: end;
}
.d-flex-center{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.d-flex{
    display: flex;
}
.mt-30{
    margin-top: 30px !important;
}
.mt-80{
    margin-top: 80px;
}
.ml-10{
    margin-left:10px;
}
.pt-50{
    padding-top: 50px;
}
.pt-90{
    padding-top: 90px;
}
.py-90{
    padding-top: 90px;
    padding-bottom: 90px;
}

button.mat-flat-button{
    border-radius: 5px !important;
    background: #ffd00d !important;
    line-height: 50px !important;
    color: black;
    font-size: 17px;
}
button.mat-flat-button mat-icon{
    color: #ffffffb5;
    font-size: 24px;
}
.br-30{
    border-radius: 30px;
}
.text-right{
    text-align: right;
}
.mt-260{
    margin-top: 260px;
}
.mt-60{
    margin-top: 60px;
}
.fw-400{
    font-weight: 400;
}
a:hover {
    color: #d3ab06!important;
}
button:hover{
    background: #d3ab06 !important;
}
h1.title {
    color: black;
    font-size: 30px;
    font-weight: 500;
}
h4.sub-title {
    color: grey;
    font-size: 18px;
    font-family: 'Roboto';
}
.mat-tab-labels{
    justify-content: space-around;
}
.mat-tab-label-content {
    font-size: 20px;
}
.mat-tab-label-active .mat-tab-label-content {
    color: #0b65da;
}
.mat-tab-label{
    padding: 40px 24px !important;
}
.mat-tab-group.mat-primary .mat-ink-bar{
    background-color: #0b65da;
    width: 165px !important;
}
//banner
.banner{
    background: #f8f4eb;
} 
.header .logo{
    color: black;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    span{
        margin-left: 15px;
    }
}
.header .logo:hover{
    color: black !important;  
}
.header ul{
    list-style: none;
    align-items: center;
    display: inline-flex;
} 
.header ul li{
 padding-left:20px;
padding-right:20px;
}
.header ul li a{
    text-decoration: none;
    color: #4b5158;
    font-weight: 400;
    font-size: 18px;
}
.header .toggle{
    display: none;
}
.header .toggle-btn mat-icon{
    color: black;
}
.header .mobile{
    position: absolute;
    background: white;
    width: 90%;
    display: list-item;
    z-index: 1;
    padding-left: 0px;
}
.header .mobile button{
    margin:0px;
}
.header .mobile li a{
    color: black;
}
.header .mobile li{
    padding: 10px;
    border-bottom: 1px solid grey;
}
.banner-content{
    padding-top: 90px;
    padding-bottom: 65px;
}
.play-btn{
    float: right;
    position: relative;
    bottom: 46px;
    right: 20%;
}
.banner h1{
    color: black;
    font-size: 75px;
    font-weight: 600;
    line-height: 80px;
}
.banner p {
    font-size: 22px;
    color: grey;
    line-height: 35px;
    margin-top: 40px;
}

.banner button{
    margin: 30px 0px;
}
.banner ul{
    padding-left: 40px;
    list-style: none;
    display: flex;
  }
  .banner img{
    position: absolute;
    width: 45%;
    top: 50px;
  }
//our solutions
.solutions {
    background: white;
    color: grey;
    padding-top: 180px;
    padding-bottom: 90px;
  mat-tab-group{
    margin-top: 40px;
    .tab-content{
        margin-top: 40px;
        padding-left: 80px;
        padding-right: 80px;
        h5 {
            color: black;
            font-size: 30px !important;
            line-height: 40px!important;
            font-weight: 500;
            margin-top: 80px;
        }
        p {
            font-size: 17px;
            line-height: 30px;
            margin-top: 30px !important;
        }
        .about-content {
            padding-right: 135px;
        }
    }
  }  
}
//Features
.features{
   background: #f3fdfe;
   padding-top: 40px;
   padding-bottom: 80px;
   .row{
    align-items: center;   
   }
   .container{
    .row{
        align-items: center;
        padding-left: 150px;
     padding-right: 150px;
        .feature{
         padding-top: 40px;
         padding-bottom: 40px;
         padding-right: 65px;
         img{
             width: 50%;
         }
         h1{
             span{
                 mat-icon{
                     font-size: 40px;
                     width: 40px;
                     height: 40px;
                 }
             }
         }
         h5 {
             font-size: 30px!important;
             color: black;
             font-weight: 500;
             margin-bottom: 25px;
         }
         p {
             color: grey;
             font-size: 20px;
             line-height: 34px;
         }
        }
        .feature.img-sec{
            text-align: center;
        }
     
    }
   }
   
}
//about us
.about-us {
    background: #f5f5f5;
    .about-content{
        padding-right: 160px;
    }
}

.about-us .about-content h5{
    font-size: 20px;
}
.about-us .about-content h2{
    font-size: 50px;
    font-weight: 600;
    margin-top: 25px;
}
.about-us .about-content h3{
    font-size: 24px;
    margin-top: 45px;
}
.about-us .about-content p{
    font-size: 16px;
    line-height: 30px;
    font-weight: 400;
    text-align: justify;
    margin-top:25px;
}

//Classes
.classes {
background: url('/assets/images/classes-image/classes-bg.png');
background-repeat: no-repeat;
padding-bottom: 100px;
background-size: cover;
}
.class-img-sec{
    padding: 181px 50px;
    background-repeat: no-repeat;
    background-size: cover;
}
.class-img-sec1{
    background-size: cover;
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.class-img-sec h3, .class-img-sec1 h3{
    color: white !important;
}
.class-img-sec h4{
    color: #eb1e2f !important;
}
.class-img-sec p{
    color: #ffffffb0;
    font-size: 17px;
    line-height: 25px;
}
.classes h1{
  color:white;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  font-size: 40px;
  padding-bottom: 20px;
}
.classes h3 {
    color: #eb1e2f;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: 24px; 
    padding-top: 25px;
}

//trainers
.trainers {
    background: #242424;
    color: white;
    padding-top: 25px;
    padding-bottom: 90px;
}
.trainers h1{
    color:white;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: 40px;
    padding-bottom: 20px;
  }
  .trainers h3 {
      color: #eb1e2f;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      font-size: 24px; 
      padding-top: 25px;
  }
  .trainers ul{
    padding-left: 0px;
    list-style: none;
    display: flex;
  }
  .trainers ul li {
    padding-right: 15px;
    padding-left: 15px;
}
  .trainers .d-flex-center h3{
     color: white !important;
  }
  .trainers .card-footer{
    background: #eb1e2f;
    position: relative;
    bottom: 5px;
  }
  .trainers .card-footer h3{
    font-size: 16px !important;
    padding-top: 14px !important;
    font-weight: 500;
  }

  //Gallery
  .gallery {
    padding-bottom: 50px;
  }
  .gallery h3 {
    color: #eb1e2f;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    font-size: 24px; 
    padding-top: 25px;
  }
  .gallery img{
    margin-bottom: 10px;
  }

//Testimonials
.testimonials {
    background: #fffbf8;
    padding: 80px;
    .slider{
        .row{
            // margin: 60px 150px;
            padding: 40px 60px;
            background: #ffffff;
            border-radius: 15px;
            p{
                font-size: 18px;
                line-height: 35px;
            }
          }
        .userimg {
            display: flex;
            justify-content: space-around;
            padding-right: 50px;
            img{
                width: 80px !important;
                height: 80px !important;
                border-radius: 50% !important;
            }
            div.user-details{
                display: flex;
                align-items: center;
                div{
                    padding-left: 15px;
                    h3{
                        font-size: 20px;
                        font-weight: 500;
                        margin-bottom: 5px;  
                    }
                    h5{
                        font-size: 16px;
                        margin-bottom: 0px;  
                    }
                }
                
            }
            
        }
        .carousel-cell{
            .userimg{
                div{
                  display: none;  
                }
            }
        }
        .carousel-cell:nth-child(3){
            .userimg{
                div{
                  display: block!important;  
                }
            }
        }
    }
    .arrow-down {
        width: 0; 
        height: 0; 
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid white;
        position: relative;
        left: 50%;
        bottom: 0px;
   
      }
      

}

//Membership
.membership {
    padding-left: 225px;
    padding-right: 225px;
    padding-top: 50px;
    padding-bottom: 35px;
    .row{
        border: 1px solid #80808070;    
        margin-top: 100px;
        border-radius: 11px;
        .price-details{

            padding-bottom: 30px; 
            padding: 40px 0px;  
            border-radius: 40px;
            h1{
                font-size: 27px;
                font-weight: 600;
                color: #231d4f;
                margin-bottom: 0px;
            }
            h2{
                font-size: 45px;
                color: #231d4f;
                span{
                    font-size: 18px;
                    color: grey;
                    padding-left: 5px;
                }
                
            }
            h3{
                font-size: 30px;
                color: black;
                font-weight: 600;
                padding-top: 10px;
            }
            p{
                color: darkgrey;
                font-size: 17px;
                line-height: 30px;
                margin-top: 15px;
            }
            ul{
                list-style-type: none;
                padding-left: 0px;
                li{
                    color: darkgrey;
                        padding: 10px;
                        font-size: 16px;
                        mat-icon{
                            background: #eeecf9;
                            color: #5a4cc5;
                            border-radius: 50%;
                            padding: 2px;
                            font-size: 20px;
                            line-height: 24px;
                            position: relative;
                            top: 5px;
                            right: 10px;
                        }
                   
                }
            }
            button.mat-flat-button{
                width: 100%;
                color: #000000a3;
                margin-top: 20px;
                margin-bottom: 5px;
                background: #8080802b!important;
                border-radius: 35px !important;
                line-height: 54px !important;
            }
        }
        .active{
            color: white;
            padding: 15px;
            border-radius: 25px;    border-radius: 25px;
    position: relative;
    bottom: 60px;
            .price-details{
                width: 300px;
                background: blue;
                padding: 40px 30px;
                h1{
                    color: #231d4f;
                }
                h2{
                    color: #231d4f;
                    span{
                        color: white;
                    }
                    
                }
                h3{
                    color: #25d781 !important;
                }
                p{
                    color: darkgrey;
                }
                ul{
                    li{
                        color: white;
                            mat-icon{
                                background: #2374de;
        color: white;
                               
                            }
                       
                    }
                }
                button.mat-flat-button{
                   background: #000000d1!important;
                   color:white;
                }
            }
        }
    }
 
   
}

//Footer
.footer{
    background: #0b0d17; 
    color: white;
}
.footer .heading{
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 1px;
}
.footer .contact-sec h5{
    font-size: 18px;
    margin-bottom: 7px;
}
.footer ul{
    padding-left: 0px;
    list-style: none;
    margin-top: 0px;
}
.footer .contact-sec ul li{
    font-size: 18px;
    line-height: 26px;
}
.footer .contact-sec ul li mat-icon{
    position: relative;
    top: 7px;
}
.footer .contact-sec ul li span{
    padding-left: 5px;
}
.footer ul li{
    padding: 10px 0px;
}
.footer ul li a{
    color: white;
    text-decoration: none;
    font-size: 18px;
}
.footer .d-flex-center-end li{
    padding-left: 25px;
}
.footer button{
    line-height: 25px;
    color: white;
    padding: 2px 30px;
}
.footer h5.copyright{
    font-size: 16px;
    color: #ffffffab;
}
.footer .copy-right{
    align-items: center;
    border-top: 1px solid grey;
}
.footer .copy-right ul li{
    background: #23252e;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    margin: 10px;
    height: 23px;
}
.footer .copy-right ul li:hover {
    background: #56575b;
}
.powered-by{
    text-align: right;
    position: relative;
    bottom: 21px;
    margin-bottom: 0px;
    font-size: 14px;
}
.powered-by a{
    color: white;
}
// Responsive
// 1024px
@media(max-width: 1024px) {
 
    .pr-70 {
        padding-right: 25px;
    }

    .about-us .about-content button {
        margin-top: 15px;
        margin-bottom: 20px;
    }
  
    .membership .price-details h2 {
        font-size: 20px;
    }
    .membership .price-details h1 {
        font-size: 30px;
    }
    .membership .price-details h3 {
        font-size: 20px;
    }
    .footer .contact-sec img{
        width: 70%;
    }
}
//768px
@media(max-width: 768px){
    .header ul li {
        padding-left: 14px;
        padding-right: 14px;
    }
    .header ul li a{
        font-size: 14px;
    }
    .about-us .about-content h2 {
        font-size: 40px;
    }
    .about-us .about-content h3 {
        font-size: 22px;
        margin-top: 20px;
    }
    .features .container .row .feature h5{
        font-size: 22px !important;
        margin-bottom: 15px;
    }
    .membership .row .price-details h2 {
        font-size: 25px;
    }
    .membership .row .price-details h3 {
        font-size: 20px;
    }
    .membership .row .active .price-details {
        padding: 16px 20px;
    }
    .membership .mt-30 {
        margin-top: 10px !important;
    }
    .pr-45 {
        padding-right: 10px;
    }
    .about-us .about-content{
        padding-right: 30px;
        padding-top: 50px;
        padding-left: 0px;
    } 
    .about-us .about-content p{
        line-height: 24px;
    }
    h1.title {
        font-size: 25px;
    }
    .solutions mat-tab-group .tab-content h5 {
        font-size: 24px !important;
        line-height: 33px !important;
    }
    .features .container .row .feature p{
        font-size: 18px;
    line-height: 28px;
    }
    .banner h1 {
        font-size: 25px !important;
        line-height: 31px !important;
    }
    .banner p {
        font-size: 19px;
        line-height: 30px;
        margin-top: 20px;
    }
    .banner button {
    margin-top:0px;
    }
    .features .container .row .feature img {
        width: 70%;
    }
    .footer .contact-sec ul li {
        font-size: 15px;
    }
    .footer ul li {
        padding: 5px 0px;
    }
    .footer ul li a {
      font-size: 15px;
    }
    .footer h2 {
        font-size: 15px !important;
    }
    .footer .contact-sec h5 {
        font-size: 15px;
    }
    .mt-260 {
        margin-top: 210px;
    }
    .membership .price-details h2{
        margin-bottom: 5px;
    }
    .membership .price-details h3 {
        padding-top: 5px;
        padding-bottom: 0px;
    }
    .py-90 {
        padding-top: 0px !important;
        padding-bottom: 25px !important;
    }

}
@media(max-width: 767px){
    .header ul.web{
        display: none;
    }
    .header .toggle{
        display: block!important;
    }
}
@media (min-width: 768px){
    .header .toggle-btn{
        display: none!important;
    }   
}
// mobile screen
@media (max-width: 591px){
    .header .row{
        justify-content: space-between !important;
    }
    .reverse{
        flex-direction: column-reverse !important;
    }
    .banner h1 {
        font-size: 15px;
        line-height: 22px;
    }
    .banner p {
        line-height: 18px;
        margin-top: 10px;
    }
    .banner button {
        margin: 0px 0px;
    }
    .banner-content {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    .banner p {
        font-size: 12px;
    }
    .membership,.features .row,.solutions mat-tab-group .tab-content,.solutions mat-tab-group .tab-content .about-content{
        padding-right: unset!important;
        padding-left: unset!important;
    }
    .solutions mat-tab-group .tab-content h5{
        margin-top:unset!important;
    }
    .features .row .feature{
        padding-bottom: unset!important;
        padding-right: unset!important;
    }
    .membership .row .active .price-details{
        width: unset!important;
    }
    .about-us{
        padding-bottom: 0px;
        .wow.bounceInRight{
            padding-right: unset!important;
        }
        .about-content img{
            width: 46%;
        }
    }
    .solutions {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .card {
        margin-bottom: 20px;
    }
    .membership .mt-80 {
        margin-top: 0px; 
    }
    .membership .price-details{
        margin-top: 25px;
    }
    .testimonials{
        padding:20px 0px;
    }
    .testimonials .slider .row{
        margin: -15px;
        padding:20px;
    }
    .footer h3.heading {
        margin-top: 35px;
    }
    .footer .mt-60 {
        margin-top: 0px;
    }
    .footer .text-right {
        text-align: left;
        margin-top: 35px;
    }
    .footer .mt-260 {
        margin-top: 30px;
    }
    .footer .d-flex-center-end{
        justify-content: start;   
    }
    .footer .d-flex-center-end li {
        padding-right: 25px;
        padding-left:0px;
    }
    .pl-90 {
        padding-left: 35px;
    }
    .owl-item .row{
     width: 100%;
    }
    .owl-item.center .row{
        width: unset!important;
       }
    .membership .row{
        margin-top: 30px;
    }
}
@media (max-width: 1024px) and (min-width: 592px){
.membership,.features .row,.solutions mat-tab-group .tab-content,.solutions mat-tab-group .tab-content .about-content{
    padding-right: unset!important;
    padding-left: unset!important;
}
.solutions mat-tab-group .tab-content h5{
    margin-top:unset!important;
}
.features .row .feature{
    padding-bottom: unset!important;
    padding-right: unset!important;
}

.membership .row{
    margin-top: 30px!important;
}
.pl-90 {

    padding-left: 25px;
}
.py-90{
    padding-top: 50px;
    padding-bottom: 50px;
}
.membership .row .active .price-details{
    width: unset!important;
}
.about-us{
    padding-bottom: 0px;
    .wow.bounceInRight{
        padding-right: unset!important;
    }
    .about-content img{
        width: 46%;
    }
}
.banner h1 {
    font-size: 40px;
    line-height: 48px;
}
.banner-content{
    padding-bottom: 0px;
}
.header .row{
    justify-content: space-between !important;
}
.solutions {
    padding-top: 30px;
    padding-bottom: 30px;
}
.card {
    margin-bottom: 20px;
}
.membership .mt-80 {
    margin-top: 0px; 
}
.membership .price-details{
    margin-top: 25px;
}
.testimonials{
    padding:20px 0px;
}
.testimonials .slider .row{
    margin:0px;
    padding:20px;
}
.footer h3.heading {
    margin-top: 35px;
}
.footer .mt-60 {
    margin-top: 0px;
}
.footer .text-right {
    text-align: left;
    margin-top: 35px;
}
.footer .mt-260 {
    margin-top: 30px;
}
.footer .d-flex-center-end{
    justify-content: start;   
}
.footer .d-flex-center-end li {
    padding-right: 25px;
    padding-left:0px;
}
}
@media (max-width: 416px){
.trainers .card .trainer-img {
    height: 350px !important;
}
.testimonials .px-50 {
   padding-left: 0px;
   padding-right: 0px; 
}
.container {
    padding-left: 25px;
    padding-right: 25px;
}

}

@media (max-width: 590px) {
    .footer .text-center{
        border-top: 1px solid white;
        padding-top: 15px;
    }
    .footer span.copyright{
        border:none;
        padding-left: unset!important;
        padding-right: unset!important;
    }
    h3.title {
        font-size: 24px !important;
    }
    
    
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard, .mat-button-toggle-group-appearance-standard {
    border-radius: 25px !important;
    line-height: 35px;
}
.mat-button-toggle-button{
    border-radius: 20px;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 35px;
}
.mat-button-toggle-checked {
    background-color: #ffd00d;
    border-radius: 20px;
}
.mat-button-toggle-group-appearance-standard .mat-button-toggle+.mat-button-toggle {
   border-left: none;
}
.slider .row,.slider .user-details div, .slider .arrow-down{
    visibility: hidden;
}
.owl-item.center{
    .slider .row,.slider .user-details div, .slider .arrow-down{
        visibility: visible!important;
    }
}
.owl-item{
    width: 231.8px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.owl-item.center .row{
// position: absolute;
// top: 1%;
// right: -128%;
width: 685px;

}